import React from 'react'
import SbEditable from 'storyblok-react'
import { Box, Typography, ListItemText, useMediaQuery } from '@material-ui/core'
import renderBloks from '@renderBloks'
import { makeStyles } from '@material-ui/styles'
import ACC_THEME from '@themes'

const useStyles = makeStyles(theme => ({
  description: {
    fontSize: 12,
    fontWeight: theme.typography.fontWeightMedium,
  },
}))

const TopNavTabNavItemCard = props => {
  const classes = useStyles(props)

  const handHeldDevice = useMediaQuery(ACC_THEME.breakpoints.down('sm'))

  return (
    <SbEditable key={props.blok._uid} content={props.blok}>
      {!handHeldDevice ? (
        <Box width="22%">
          <Box mb={1}>{renderBloks(props.blok.navItemLink)}</Box>

          <Typography className={classes.description}>
            {props.blok.description}
          </Typography>
        </Box>
      ) : (
        <ListItemText
          primary={renderBloks(props.blok.navItemLink)}
          className={classes.lifeCycleHeadline}
        />
      )}
    </SbEditable>
  )
}

export default TopNavTabNavItemCard
